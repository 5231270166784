import React from 'react'

function Wingo10Min() {
    return (
        <div>
            Wingo10Min
        </div>
    )
}

export default Wingo10Min
